import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import { GatsbyImage } from "gatsby-plugin-image";

export default function render({ data }) {
    return (
        <Layout>
            <div className="grid grid-cols-2 gap-8">
            {/* className="grid gap-[5%] grid-template-columns[1fr_1fr]" */}
                <div>
                    <a href="https://salcmapcrafter.n00bs.info/" target="_blank" rel="noreferrer">
                        <h2>2D Render</h2>
                        <p>The 2D Render is a top down view on the map.</p>
                        <GatsbyImage image={data.img1.childImageSharp.gatsbyImageData} />
                    </a>
                </div>
                <div>
                    <a href="https://salcbluemap.n00bs.info/#world:0:258:0:0:0:1.57:0:0:free" target="_blank" rel="noreferrer">
                        <h2>3D Render</h2>
                        <p>The 3D "Render" is done via bluemap, you can fly around like in spectatormode.</p>
                        <GatsbyImage image={data.img2.childImageSharp.gatsbyImageData} />
                    </a>
                </div>
            </div>
        </Layout>
    );
}


export const query = graphql`query renderimages {
  img1: file(relativePath: {eq: "render/salc_anarchy_2D_render.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  img2: file(relativePath: {eq: "render/salc_anarchy_3D_render.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

